import { TDynamicFeatureCoreInitializer, TDynamicFeaturesResolverRecord } from '@dreamcommerce/star_core';

import { ADD_TO_FAVOURITES_NAMES } from '@storefrontFeatures/add_to_favourites/add_to_favourites_constants';
import { BLOG_ARTICLE_COMMENT_FORM_NAMES } from '@storefrontRoot/features/blog_article_comment_form/blog_article_comment_form_constants';
import { CONTACT_FORM_NAMES } from '@storefrontRoot/features/contact_form/contact_form_constants';
import { CUSTOMER_PRIVACY_INITIALIZER_NAME } from '@storefrontFeatures/customer_privacy/customer_privacy_constants';
import { FOOTER_GROUPS_NAMES } from '@storefrontFeatures/footer_groups/footer_gropus_constants';
import { FORM_MANAGEMENT_NAMES } from '@storefrontFeatures/form_management/form_management_constants';
import { LANGUAGE_AND_CURRENCY_NAMES } from '@storefrontRoot/features/language_and_currency/language_and_currency_constants';
import { LIST_ITEMS_AGGREGATOR_NAMES } from '@storefrontRoot/features/list_items_aggregator/list_item_aggregator_constants';
import { MENU_NAMES } from '@storefrontRoot/features/menu/menu_constants';
import { NEWSLETTER_FORM_NAMES } from '@storefrontRoot/features/newsletter_form/newsletter_form_constants';
import { PAGINATION_PAGE_NUMBER_NAMES } from '@storefrontRoot/features/pagination/page_number/pagination_page_number_constants';
import { PHOTO_SLIDER_NAMES } from '@storefrontRoot/features/photo_slider/photo_slider_constants';
import { PRODUCT_AVAILABILITY_NAMES } from '@storefrontRoot/features/product_availability/product_availability_constants';
import { PRODUCT_CODES_NAMES } from '@storefrontRoot/features/product_codes/product_codes_constants';
import { PRODUCT_DESCRIPTION_NAMES } from '@storefrontRoot/features/description_matcher/product_description/product_description_constants';
import { PRODUCT_GALLERY_NAMES } from '@storefrontRoot/features/product_gallery/product_gallery_constants';
import { PRODUCT_PRICE_NAMES } from '@storefrontFeatures/product_price/product_price_constants';
import { PRODUCT_QUANTITY_NAMES } from '@storefrontRoot/features/product_quantity/product_quantity_constants';
import { PRODUCT_RATING_NAMES } from '@storefrontRoot/features/product_rating/product_rating_constants';
import { PRODUCT_REVIEW_NAMES } from '@storefrontRoot/features/product_review/product_review_constants';
import { PRODUCT_SHIPPING_TIME_NAMES } from '@storefrontRoot/features/product_shipping_time/product_shipping_time_constants';
import { PRODUCT_SHORT_DESCRIPTION_NAMES } from '@storefrontRoot/features/description_matcher/product_short_description/product_short_description_constants';
import { PRODUCT_VARIANTS_NAMES } from '@storefrontFeatures/product_variants/product_variants_constants';
import { RATE_AND_REVIEW_NAMES } from '@storefrontRoot/features/rate_and_review/rate_and_review_constants';
import { SEARCH_NAMES } from '@storefrontFeatures/search/search_constants';
import { BASKET_DONE_NAMES } from '@storefrontFeatures/basket_done/basket_done_constants';
import { AVAILABILITY_NOTIFIER_NAMES } from '@storefrontFeatures/availability_notifier/availability_notifier_constants';
import { FLASH_MESSENGER_NAMES } from '@storefrontFeatures/flash_messenger/flash_messenger_constants';
import { LOYALTY_PRODUCT_PRICE_NAMES } from '@storefrontRoot/features/loyalty_product_price/loyalty_product_price_constants';
import { BUY_BUTTON_NAMES } from '@storefrontFeatures/buy_button/buy_button_constants';
import { LOYALTY_BUY_BUTTON_NAMES } from '@storefrontFeatures/loyalty_buy_button/loyalty_buy_button_constants';
import { PRODUCT_BUNDLES_NAMES } from '@storefrontFeatures/product_bundles/product_bundles_constants';
import { PRODUCT_ASK_QUESTIONS_NAMES } from '@storefrontRoot/features/product_ask_questions/product_ask_questions_constants';
import { PRODUCT_SHIPPING_PRICES_NAMES } from '@storefrontRoot/features/product_shipping_prices/product_shipping_prices_constants';
import { GOOGLE_CUSTOMER_REVIEWS_NAMES } from '@storefrontFeatures/google_customer_reviews/google_customer_reviews_constants';
import { LOYALTY_PROGRAM_POINTS_NAMES } from '@storefrontRoot/features/loyalty_program_points/loyalty_program_points_constants';
import { KLARNA_BANNER_NAMES } from '@storefrontRoot/features/klarna_banner/klarna_banner_constants';
import { FILTERS_NAMES } from '@storefrontFeatures/filters/filters_constants';
import { STICKY_MOBILE_BARS_NAMES } from '@storefrontFeatures/sticky_mobile_bars/sticky_mobile_bars_constants';
import { P24_INSTALLMENTS_MODAL_NAMES } from '@storefrontRoot/features/p24_installments_modal/p24_installments_modal_constants';


export const STOREFRONT_DYNAMIC_COMPONENTS_MAPPER: TDynamicFeaturesResolverRecord[] = [
    {
        featureName: BUY_BUTTON_NAMES.component,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/buy_button/buy_button_initializer');
        }
    },
    {
        featureName: FLASH_MESSENGER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/flash_messenger/flash_messenger_initializer');
        }
    },
    {
        featureName: SEARCH_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/search/search_initializer');
        }
    },
    {
        featureName: FORM_MANAGEMENT_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/form_management/form_management_initializer');
        }
    },
    {
        featureName: PRODUCT_SHORT_DESCRIPTION_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/description_matcher/product_short_description/product_short_description_initializer');
        }
    },
    {
        featureName: PAGINATION_PAGE_NUMBER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/pagination/page_number/pagination_page_number_initializer');
        }
    },
    {
        featureName: PRODUCT_DESCRIPTION_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/description_matcher/product_description/product_description_initializer');
        }
    },
    {
        featureName: CUSTOMER_PRIVACY_INITIALIZER_NAME,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/customer_privacy/customer_privacy_initializer');
        }
    },
    {
        featureName: PRODUCT_CODES_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/product_codes/product_codes_initializer');
        }
    },
    {
        featureName: PRODUCT_GALLERY_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_gallery/product_gallery_initializer');
        }
    },
    {
        featureName: PRODUCT_PRICE_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/product_price/product_price_initializer');
        }
    },
    {
        featureName: LOYALTY_PRODUCT_PRICE_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/loyalty_product_price/loyalty_product_price_initializer');
        }
    },
    {
        featureName: MENU_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/menu/menu_initializer');
        }
    },
    {
        featureName: ADD_TO_FAVOURITES_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/add_to_favourites/add_to_favourites_initializer');
        }
    },
    {
        featureName: PRODUCT_SHIPPING_TIME_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_shipping_time/product_shipping_time_initializer');
        }
    },
    {
        featureName: RATE_AND_REVIEW_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/rate_and_review/rate_and_review_initializer');
        }
    },
    {
        featureName: PRODUCT_REVIEW_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_review/product_review_initializer');
        }
    },
    {
        featureName: PRODUCT_RATING_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_rating/product_rating_initializer');
        }
    },
    {
        featureName: FOOTER_GROUPS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/footer_groups/footer_groups_initializer');
        }
    },
    {
        featureName: PRODUCT_QUANTITY_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/product_quantity/product_quantity_initializer');
        }
    },
    {
        featureName: PRODUCT_AVAILABILITY_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_availability/product_availability_initializer');
        }
    },
    {
        featureName: PHOTO_SLIDER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/photo_slider/photo_slider_initializer');
        }
    },
    {
        featureName: PRODUCT_VARIANTS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_variants/product_variants_initializer');
        }
    },
    {
        featureName: BLOG_ARTICLE_COMMENT_FORM_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/blog_article_comment_form/blog_article_comment_form_initializer');
        }
    },
    {
        featureName: LIST_ITEMS_AGGREGATOR_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/list_items_aggregator/list_items_aggregator_initializer');
        }
    },
    {
        featureName: CONTACT_FORM_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/contact_form/contact_form_initializer');
        }
    },
    {
        featureName: NEWSLETTER_FORM_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/newsletter_form/newsletter_form_initializer');
        }
    },
    {
        featureName: LANGUAGE_AND_CURRENCY_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/language_and_currency/language_and_currency_initializer');
        }
    },
    {
        featureName: BASKET_DONE_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/basket_done/basket_done_initializer');
        }
    },
    {
        featureName: AVAILABILITY_NOTIFIER_NAMES.component,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/availability_notifier/availability_notifier_initializer');
        }
    },
    {
        featureName: LOYALTY_BUY_BUTTON_NAMES.component,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/loyalty_buy_button/loyalty_buy_button_initializer');
        }
    },
    {
        featureName: PRODUCT_BUNDLES_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/product_bundles/product_bundles_initializer');
        }
    },
    {
        featureName: PRODUCT_ASK_QUESTIONS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontRoot/features/product_ask_questions/product_ask_questions_initializer');
        }
    },
    {
        featureName: PRODUCT_SHIPPING_PRICES_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/product_shipping_prices/product_shipping_prices_initializer');
        }
    },
    {
        featureName: GOOGLE_CUSTOMER_REVIEWS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/google_customer_reviews/google_customer_reviews_initializer');
        }
    },
    {
        featureName: LOYALTY_PROGRAM_POINTS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/loyalty_program_points/loyalty_program_points_initializer');
        }
    },
    {
        featureName: KLARNA_BANNER_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/klarna_banner/klarna_banner_initializer');
        }
    },
    {

        featureName: FILTERS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/filters/filters_initializer');
        }
    },
    {
        featureName: STICKY_MOBILE_BARS_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/sticky_mobile_bars/sticky_mobile_bars_initializer');
        }
    },
    {
        featureName: P24_INSTALLMENTS_MODAL_NAMES.feature,
        getLoadingPromise(): Promise<TDynamicFeatureCoreInitializer> {
            return import('@storefrontFeatures/p24_installments_modal/p24_installments_modal_initializer');
        }
    }
];
