const basketNamespace = 'basket';

export const BASKET_EVENTS = {
    initialized: 'basket.initialized',
    basketUpdated: `${basketNamespace}.basketUpdated`,
    itemAddedToBasket: `${basketNamespace}.itemAddedToBasket`,
    itemRemovedFromBasket: `${basketNamespace}.itemRemovedFromBasket`,
    unexpectedError: `${basketNamespace}.unexpectedError`,
    setPayment: `${basketNamespace}.setPayment`,
    setShipping: `${basketNamespace}.setShipping`,
    orderPlaced: `${basketNamespace}.orderPlaced`,
    purchased: `${basketNamespace}.purchased`,
    startedFileUpload: `${basketNamespace}.startedFileUpload`,
    finishedFileUpload: `${basketNamespace}.finishedFileUpload`,
    paymentInfoAdded: `${basketNamespace}.paymentInfoAdded`,
    shippingInfoAdded: `${basketNamespace}.shippingInfoAdded`,
    setPickupPoint: `${basketNamespace}.setPickupPoint`
} as const;
