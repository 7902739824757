import { createStore, withProps } from '@ngneat/elf';
import { IStorefrontSettingsState } from '@storefrontFeatures/storefront_settings/state/storefront_settings_types';
import { STOREFRONT_SETTINGS_NAMES } from '@storefrontRoot/features/storefront_settings/storefront_settings_constants';
import { countries } from '@storefrontFeatures/storefront_settings/storefront_settings_countries_list';
import { CountryMapper } from '@storefrontRoot/models/country/country_mapper';

export const createStorefrontSettingsStore = () => {
    const countryMapper = new CountryMapper();
    const modeledCountries = countries.map((country) => countryMapper.toModel(country));

    return createStore(
        {
            name: STOREFRONT_SETTINGS_NAMES.store
        },
        withProps<IStorefrontSettingsState>({
            skinSettings: {
                currency: 'PLN',
                locale: 'pl_PL',
                decimalSeparator: '',
                thousandSeparator: '',
                isFullRegistrationMode: 'false',
                isCookieInformationEnabled: '0',
                isRegistrationEnabled: '1',
                isBuyingEnabledToUnregistered: '1',
                contextId: '',
                licenseData: ''
            },
            pageSettings: {},
            inpostPayWidgetSettings: null,
            p24InstallmentsSettings: null,
            countries: modeledCountries
        })
    );
};
